<template>
  <v-theme-provider>
    <base-section
      id="info-alt"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="5"
          >
            <base-business-contact-nanotech
              countury="leeheng"
            />
          </v-col>

          <v-col
            class="text-center hidden-sm-and-down"
            cols="2"
          >
            <v-divider vertical />
          </v-col>

          <v-col
            cols="12"
            md="5"
          >
            <base-contact-form
              :title="mailus"
              :button="button"
            />
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionBusinessInfo',
    computed: {
      mailus () {
        return this.$t('contact.mailus')
      },
      button () {
        return this.$t('contact.button')
      },
    },
  }
</script>
